<template>
  <div>
    {{ name }}
    <span style="font-style: italic; float: right">
      <span style="float:right; padding-left: 10px">
        <el-tag
          style="margin-right: 5px; height: 28px"
          size="small"
          :type="tagSpeedChartColor()"
          effect="dark"
          @click="toggleSpeedChart"
        >
          <i class="fas fa-tachometer-alt" style="color: white"></i>
        </el-tag>
        <el-tag
          :type="tagTempChart"
          style="margin-right: 5px; height: 28px"
          size="small"
          effect="dark"
          @click="toggleTempChart"
        >
          <i class="fas fa-thermometer-quarter"></i>
        </el-tag>
        <el-tag
          :style="tagFuelChartColor()"
          size="small"
          effect="dark"
          @click="toggleFuelChart"
        >
          <i class="fas fa-gas-pump" style="color: white"></i>
        </el-tag>
        <el-tag
          style="margin-right: 5px; height: 28px"
          size="small"
          :type="tagRPMChartColor()"
          effect="dark"
          @click="toggleRPMChart"
        >
          <i class="fab fa-cloudscale" style="color: white; font-size: 15px"></i>
        </el-tag>
        <el-tag
          style="margin-right: 15px; height: 28px"
          size="small"
          :type="tagEventsChartColor()"
          effect="dark"
          @click="toggleEventChart"
        >
          <i class="fas fa-bell" style="color: white; font-size: 15px"></i>
        </el-tag>
        <el-button icon="el-icon-printer" style="padding: 0; height: 25px; width:35px" @click="print" />
        <i v-if="routeFetched" class="fas fa-times" style="color: gray; padding-left: 10px" disabled @click="toggleChanged"></i>
      </span>
    </span>
    <div style="float: right">
      <i class="el-icon-d-arrow-left" style="padding-right: 5px" @click="minusDay"></i>
      <el-date-picker
        v-model="dateRange"
        :default-time="['00:00:00', '23:59:59']"
        :disabled="loadingRoutes"
        size="mini"
        type="datetimerange"
        range-separator="a"
      >
      </el-date-picker>
      <i class="el-icon-d-arrow-right" style="padding-left: 5px" @click="plusDay"></i>
    </div>
    <div class="textFormat" style="padding-top: 3px; overflow: hidden; width: 100%; white-space: nowrap;">
      <i class="fas fa-backward" @click="backward"></i>
      <i style="padding-left: 5px; padding-right: 5px"></i>
      <i class="fas fa-forward" @click="forward"></i>
      <i style="padding-left: 5px; padding-right: 15px"></i>
      <i :class="`fas fa-${isPlaying?'stop':'play'}`" @click="play"></i>
      <i style="padding-left: 5px; padding-right: 5px"></i>
      {{ formattedDate }} {{ formatAddress }}
    </div>
  </div>
</template>

<script>

import { serverBus, sharedData, vm } from '@/main'
import settings from '../../settings'
import { routeMatch } from '@/api/here'
import * as utils from '../../utils/utils'
import * as lnglat from '../../utils/lnglat'
import Vue from 'vue'
import * as animation from '../../utils/animation'
import { traccar } from '@/api/traccar-api'
import mapboxgl from 'mapbox-gl'
import { mapGetters } from 'vuex'
import * as event from '../../events'
import layerManager from './mapbox/LayerManager'
import routeLayers from './mapbox/layers/RouteLayers'
import * as notifications from '../../utils/notifications'
import VehicleDetail from '@/views/map/VehicleDetail'
import i18n from '@/lang'
import store from '@/store'
import booleanContains from '@turf/boolean-contains'
import * as helpers from '@turf/helpers'
import bboxPolygon from '@turf/bbox-polygon'
import bbox from '@turf/bbox'
import { getTripsPdf } from '@/api/reports'
import { calculateIdlePositions } from '@/utils/positions'
import { popUps } from '@/utils/lnglat'
import { maxRouteEvents, stopZoom } from '@/utils/consts'
import { partnerData } from '@/utils/partner'
import { lowSignal } from '@/utils'
import geofencesLayer from '@/views/map/mapbox/layers/GeofencesLayer'
import { getTripTotalKms } from 'fleetmap-reports/src/util/trips'

export default {
  name: 'CurrentPositionData',
  data() {
    const dateRange = [
      this.$route.query.date ? new Date(this.$route.query.date) : new Date(),
      this.$route.query.date ? new Date(this.$route.query.date) : new Date()
    ]
    dateRange[0].setHours(0, 0, 0, 0)
    dateRange[1].setHours(23, 59, 59, 0)

    return {
      dateRange,
      checked: true,
      currentPos: 0,
      oldPos: 0,
      width: 'width:0px',
      currentTrip: 0,
      elSwitchValue: true,
      totalDistance: 0,
      formattedDate: '',
      popup: new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        className: 'popup-content',
        anchor: 'top',
        offset: 15
      }),
      isSpeedChartVisible: true,
      isFuelChartVisible: true,
      isRPMChartVisible: true,
      isEventsChartVisible: true,
      isTempChartVisible: false
    }
  },
  static() {
    return {
      speedTrips: [],
      speedMarkers: [],
      startMaker: null,
      endMarker: null
    }
  },
  computed: {
    ...mapGetters(['routeFetched', 'user', 'minPos', 'maxPos', 'isPlaying', 'historyMode', 'geofences', 'currentTime', 'trips', 'popupOpened']),
    zoomToDevice() {
      return this.user.attributes.zoomToDevice || partnerData().zoomToDevice
    },
    tagTempChart() {
      return this.isTempChartVisible ? 'primary' : 'info'
    },
    speedMarkers: {
      get() { return this.$static.speedMarkers },
      set(value) { this.$static.speedMarkers = value }
    },
    map() {
      return vm.$static.map
    },
    routeSource() {
      return 'route-' + this.device.id + '-' + this.currentTrip + '-' + this.i
    },
    routeSpeedSource() {
      return 'route-' + this.device.id + '-' + this.currentTrip + '-' + this.i + 'speedalert'
    },
    routeIdleSource() {
      return 'route-' + this.device.id + '-' + this.currentTrip + '-' + this.i + 'idle'
    },
    routeEventsSource() {
      return 'route-' + this.device.id + '-' + this.currentTrip + '-' + this.i + 'events'
    },
    allTripsSource() {
      return 'allTrips'
    },
    allTripsArrowsSource() {
      return 'allTrips-arrows'
    },
    positions: {
      get() {
        return vm.$data.positions
      },
      set(value) {
        vm.$data.positions = value
      }
    },
    isMobile() {
      return lnglat.isMobile()
    },
    tripDistance: {
      get() { return vm.$data.distance },
      set(value) { vm.$data.distance = value }
    },
    loadingRoutes: {
      get() { return vm.$data.loadingRoutes },
      set(value) { vm.$data.loadingRoutes = value }
    },
    name() {
      if (this.device) {
        return this.device.name
      }
      return ''
    },
    device() {
      return vm.$data.currentDevice
    },
    formatAddress: function() {
      return utils.formatAddress(this.currentPos)
    },
    showRoutes() {
      return this.historyMode
    },
    minDate() {
      return vm.$data.routeMinDate
    },
    maxDate() {
      return vm.$data.routeMaxDate
    },
    pois() {
      return this.geofences.filter(g => g && g.area && g.area.startsWith('CIRCLE'))
    }
  },
  watch: {
    dateRange() {
      this.datesChanged()
    }
  },
  created() {
    Vue.$log.debug('CurrentPositionData created')
    window.addEventListener('resize', this.resizeDiv)
    serverBus.$on(event.posChanged, (newPos) => this.onPosChanged(newPos, true))
    serverBus.$on(event.routePlay, this.routePlay)
    vm.$static.map.on('click', this.allTripsArrowsSource, this.mouseEnterArrow)
  },
  beforeDestroy() {
    Vue.$log.info('CurrentPositionData')
    window.removeEventListener('resize', this.resizeDiv)
    serverBus.$off(event.posChanged, this.onPosChanged)
    serverBus.$off(event.routePlay, this.routePlay)
    vm.$static.map.off('click', this.allTripsArrowsSource, this.mouseEnterArrow)
    const lastPos = vm.$data.currentDevice.position
    // put the vehicle back where it was...
    if (lastPos) {
      layerManager.updateFeature(vm.$static.currentFeature, lastPos)
    }
    this.removeLayers()
  },
  async mounted() {
    try {
      if (this.device && lnglat.popUps[this.device.id]) {
        lnglat.popUps[this.device.id].remove()
      }
      this.loadingRoutes = true
      await this.getRoute(this.dateRange[0], this.dateRange[1])
      vm.$data.currentDevice = this.device
    } catch (e) {
      console.warn(e)
      this.$alert(e.message)
      this.$store.commit('transient/SET_ROUTE_FETCHED', true)
    }
  },
  methods: {
    play() {
      window.open(`/traccar-route-replay?deviceId=${
        this.device.id}&from=${
        this.dateRange[0]}&to=${
        this.dateRange[1]}`)
    },
    backward() {
      serverBus.$emit('clickBack')
    },
    forward() {
      serverBus.$emit('clickForward')
    },
    minusDay() {
      this.dateRange = [
        new Date(this.dateRange[0] - 1000 * 60 * 60 * 24),
        new Date(new Date(this.dateRange[0] - 1000 * 60 * 60 * 24).setHours(23, 59, 59, 0))]
    },
    plusDay() {
      const nextDay = new Date(this.dateRange[0].getTime() + 1000 * 60 * 60 * 24)
      if (nextDay.getDay() <= new Date().getDay()) {
        this.dateRange = [
          nextDay,
          new Date(new Date(nextDay).setHours(23, 59, 59, 0))]
        console.log('dr', this.dateRange)
      }
    },
    async print() {
      this.$store.commit('transient/SET_LOADING', true)
      const pdf = await getTripsPdf(this.device, this.dateRange[0], this.dateRange[1])
      pdf.addPage()
      const map = vm.$static.map.getCanvas()
      const graph = document.querySelector('#speedChart')
      pdf.addImage(map.toDataURL(), 'PNG', 8, 8, 280, 130)
      if (this.device.attributes.notes) {
        pdf.text(this.device.attributes.notes, 8, 144)
      }
      pdf.addImage(graph.toDataURL(), 'PNG', 8, 146, 280, 50)
      window.open(pdf.output('bloburl'))
      this.$store.commit('transient/SET_LOADING', false)
    },
    tagSpeedChartColor() {
      return this.isSpeedChartVisible ? 'success' : 'info'
    },
    toggleSpeedChart() {
      serverBus.$emit(event.toogleSpeedChart)
      this.isSpeedChartVisible = !this.isSpeedChartVisible
    },
    tagFuelChartColor() {
      return (this.isFuelChartVisible ? 'background-color: black;' : 'background-color: gray;') + 'margin-right: 5px; height: 28px'
    },
    tagRPMChartColor() {
      return this.isRPMChartVisible ? 'primary' : 'info'
    },
    tagEventsChartColor() {
      return this.isEventsChartVisible ? 'danger' : 'info'
    },
    toggleFuelChart() {
      serverBus.$emit(event.toogleFuelChart)
      this.isFuelChartVisible = !this.isFuelChartVisible
    },
    toggleTempChart() {
      serverBus.$emit(event.toggleTempChart)
      this.isTempChartVisible = !this.isTempChartVisible
    },
    toggleRPMChart() {
      serverBus.$emit(event.toogleRPMChart)
      this.isRPMChartVisible = !this.isRPMChartVisible
    },
    toggleEventChart() {
      this.isEventsChartVisible = !this.isEventsChartVisible
      serverBus.$emit(event.toogleEventChart)
      layerManager.hideLayer(this.routeEventsSource, !this.isEventsChartVisible)
      this.speedMarkers.map(m => this.isEventsChartVisible ? m.addTo(vm.$static.map) : m.remove())
    },
    toggleChanged() {
      vm.$store.dispatch('transient/toggleHistoryMode')
    },
    drawTripData: function(positions) {
      this.drawTrip()
      if (this.$store.getters.viewEventsOnRoute) {
        this.drawEventsPoints(positions)
      }
      if (this.$store.getters.viewIdlePoints) {
        this.drawIdlePoints(positions)
      }
      if (this.$store.getters.viewSpeedAlerts) {
        this.drawSpeedTrip()
      }
    },
    async onPositions({ route, trips, stops }) {
      const self = this
      const positions = utils.filterPositions(route)
      this.removeLayers()
      if (positions && positions.length > 1) {
        Vue.$log.debug('got ', positions.length, ' positions')
        this.drawAll(positions)
        if (settings.animateMarkers) {
          // deckLayer.start(positions)
        }
        if (this.$store.getters.viewEventsOnRoute) {
          await this.getEvents(this.dateRange[0], this.dateRange[1], positions)
        }
        await this.getTrips(positions, trips, stops)
        Vue.$log.debug(this.trips.length, 'trips')
        this.currentTrip = this.trips.length - 1
        if (vm.$store.getters.viewSpeedAlerts) {
          await this.getSpeedTrips(positions)
        }
        this.drawTripData(positions)
        if (vm.$static.map.getLayer(geofencesLayer.pois(store).id)) {
          vm.$static.map.removeLayer(geofencesLayer.pois(store).id)
          vm.$static.map.addLayer(geofencesLayer.pois(store))
        }

        let lastPosition = null
        positions.forEach(function(p) {
          const adc1CacheValues = lastPosition === null || !(lastPosition.adc1CacheValues) ? [] : lastPosition.adc1CacheValues
          utils.calculateFuelLevel(adc1CacheValues, p, lastPosition, self.device)
          lastPosition = p
        })
        sharedData.setPositions(positions)
        this.totalDistance = Math.round(lnglat.arrayDistance(positions.map(x => [x.longitude, x.latitude])))
        Vue.$log.debug('emit routeFetched')
        serverBus.$emit('routeFetched')
      } else {
        this.$alert(this.$t('route.nodata'))
      }
      this.loadingRoutes = false
      this.$store.commit('transient/SET_ROUTE_FETCHED', true)
    },
    removeLayers(keepMain) {
      layerManager.removeCurrentRouteLayer()

      for (this.currentTrip = 0; this.currentTrip < 1000; this.currentTrip++) {
        if (vm.$static.map.getLayer(this.routeSource)) {
          vm.$static.map.removeLayer(this.routeSource)
        }
        if (vm.$static.map.getLayer(this.routeSource + 'arrows')) {
          vm.$static.map.removeLayer(this.routeSource + 'arrows')
        }
        if (vm.$static.map.getSource(this.routeSource)) {
          vm.$static.map.removeSource(this.routeSource)
        }
        if (vm.$static.map.getSource(this.routeSource + 'arrows')) {
          vm.$static.map.removeSource(this.routeSource + 'arrows')
        }
        if (vm.$static.map.getLayer(this.routeSpeedSource)) {
          vm.$static.map.removeLayer(this.routeSpeedSource)
          vm.$static.map.removeSource(this.routeSpeedSource)
        }
        if (vm.$static.map.getLayer(this.routeIdleSource)) {
          Vue.$log.debug('removing ', this.routeIdleSource)
          vm.$static.map.off('click', this.routeIdleSource, this.onIdleMouseEnter)
          vm.$static.map.removeLayer(this.routeIdleSource)
          vm.$static.map.removeSource(this.routeIdleSource)
        }
        if (vm.$static.map.getLayer(this.routeEventsSource)) {
          Vue.$log.debug('removing ', this.routeEventsSource)
          vm.$static.map.off('click', this.routeEventsSource, this.onEventMouseEnter)
          vm.$static.map.removeLayer(this.routeEventsSource)
          vm.$static.map.removeSource(this.routeEventsSource)
        }
      }
      if (this.startMaker) { this.startMaker.remove() }
      if (this.endMarker) { this.endMarker.remove() }
      this.speedMarkers.map(m => m.remove())
      if (!keepMain) {
        if (this.map.getLayer(this.allTripsSource)) {
          this.map.removeLayer(this.allTripsSource)
          this.map.removeLayer(this.allTripsSource + 'casing')
          this.map.removeLayer(this.allTripsArrowsSource)
          this.map.removeSource(this.allTripsSource)
          this.map.removeSource(this.allTripsArrowsSource)
        }
      }
    },
    async getRoute(from, to) {
      Vue.$log.debug('getting route from', from, 'to', to)
      const allInOne = await traccar.allInOne(this.device.id, from, to, ['route', 'trips', 'stops'])
      await this.onPositions(allInOne)
    },
    async getEvents(from, to, positions) {
      Vue.$log.debug('getting events from ', from, ' to ', to)
      const fromDate = Vue.moment(from).startOf('day').toDate()
      const toDate = Vue.moment(to).endOf('day').toDate()
      const responseEvents = await traccar.report_events(fromDate, toDate, [this.device.id],
        ['geofenceExit', 'ignitionOff', 'geofenceEnter', 'deviceOverspeed', 'deviceFuelDrop', 'driverChanged', 'alarm'])
      const events = responseEvents.map(d => d.data).flat()
      if (events.length < maxRouteEvents) {
        events.forEach(e => {
          const p = positions.find(p => p.id === e.positionId)
          if (p) {
            p.events ? p.events.push(e) : p.events = [e]
          }
        })
      }
    },
    async getTrips(positions, trips, stops) {
      const self = this
      const _trips = []
      if (trips) {
        const fuelInfo = this.device.attributes.xpert || positions[0].attributes.fuel
        const firstStopIndex = !stops.length || !trips.length ? 0 : (new Date(trips[0].startTime) < new Date(stops[0].startTime) ? 0 : 1)
        trips.forEach(function(t, index) {
          const stopIndex = firstStopIndex + index
          const stop = stops.length > stopIndex ? stops[stopIndex] : null
          const startTime = new Date(t.startTime).getTime()
          const endTime = new Date(t.endTime).getTime()
          const locations = positions.filter(p => {
            const pDate = new Date(p.fixTime).getTime()
            return pDate >= startTime && pDate <= endTime
          })
          let fuelConsumption = (t.spentFuel > 0 ? t.spentFuel : 0) + (stop && stop.spentFuel > 0 ? stop.spentFuel : 0)

          if (self.device.attributes.xpert) {
            const fuelUsed = positions.find(p => p.attributes.fuelUsed)
            if (!fuelUsed) {
              if (fuelConsumption > 0) {
                fuelConsumption = (fuelConsumption * self.device.attributes.fuel_tank_capacity) / 100
              } else {
                const xpertlocations = positions.filter(p => {
                  const pDate = new Date(p.fixTime).getTime()
                  return (index === 0 || pDate >= new Date(t.startTime).getTime()) &&
                    (stop ? pDate <= new Date(stop.endTime).getTime() : pDate <= endTime)
                })
                const xpertMessages = xpertlocations.map(p => p.attributes.xpert).flat().filter(p => p)
                const xpertEndTripMessage = xpertMessages.filter(x => x.type === '3')
                if (xpertEndTripMessage.length > 1) {
                  const diff = xpertEndTripMessage[xpertEndTripMessage.length - 1].total_fuel - xpertEndTripMessage[0].total_fuel
                  fuelConsumption = Math.round(diff)
                }
              }
            }
          }

          const tripAndStopPositions = stop
            ? positions.filter(p => startTime <= new Date(p.fixTime).getTime() &&
              new Date(stop.endTime).getTime() >= new Date(p.fixTime).getTime()) : locations

          const idlePositions = calculateIdlePositions(tripAndStopPositions)

          tripAndStopPositions.forEach(p => {
            if (p.events) {
              p.events.forEach(e => {
                notifications.addEventInfo(e, false, stop)
              })
            }
          })

          _trips.push({
            positions: locations,
            idlePositions: idlePositions,
            trip_start_fixtime: new Date(t.startTime),
            trip_end_fixtime: t.endTime && new Date(t.endTime),
            trip_end_address: t.endAddress,
            trip_driving_time: t.duration / 1000,
            trip_idle_time: (t.idleTime + (stop ? stop.engineHours : 0)) / 1000,
            trip_stop_time: stop ? ((new Date(stop.endTime) - new Date(stop.startTime)) - stop.engineHours) / 1000 : 0,
            trip_distance: getTripTotalKms(t, stop, locations) * 1000,
            trip_avg_speed: Math.round(t.averageSpeed * 1.85200),
            endPoi: self.findNearestPOI(locations[locations.length - 1]),
            fuelInfo: fuelInfo,
            fuel_consumption: Number((fuelConsumption || 0).toFixed(1)),
            avg_fuel_consumption: Math.round(t.distance > 0 ? (fuelConsumption || 0) * 100 / (t.distance / 1000) : 0)
          })
        })
        const endTime = trips.length && new Date(trips[trips.length - 1].endTime).getTime()
        const locations = trips.length ? positions.filter(p => new Date(p.fixTime).getTime() >= endTime) : positions
        const currentTrip = this.getCurrentTrip(locations)

        if (currentTrip) {
          _trips.push(currentTrip)
        } else {
          if (_trips.length) {
            const [lastItem] = _trips.slice(-1)
            lastItem.trip_stop_time = undefined
          }
        }
      }
      this.$store.commit('transient/SET_TOTAL_DISTANCE', _trips.reduce((a, b) => a + b.trip_distance, 0))
      this.$store.commit('transient/SET_TRIPS', _trips)
      this.$store.commit('transient/SET_STOPS', stops)
    },
    getCurrentTrip(positions) {
      const locations = []
      let startPos = false
      for (const position of positions) {
        if (!startPos) {
          if ((!position.attributes.ignition && !position.attributes.motion) ||
            (position.attributes.power > 0 && position.attributes.power < 13)) {
            continue
          }
          locations.push(position)
          startPos = true
          continue
        }

        locations.push(position)

        if ((!position.attributes.ignition && !position.attributes.motion) ||
          (position.attributes.power > 0 && position.attributes.power < 13)) {
          locations.length = 0
          startPos = false
        }
      }

      // last trip not finished
      if (locations.length > 0) {
        return this.createTrip(locations)
      }

      return undefined
    },
    createTrip(locations) {
      const distance = lnglat.arrayDistance(locations.map(x => [x.longitude, x.latitude])) * 1000

      const timeLocations = []
      for (var i = 1; i < locations.length; i++) {
        const diffSeconds = this.$moment(locations[i].fixTime).diff(this.$moment(locations[i - 1].fixTime), 'seconds')
        timeLocations.push(
          {
            type: locations[i].speed < 4 ? 1 : 0,
            time: diffSeconds,
            speed: locations[i].speed * 1.852,
            position: locations[i]
          })
      }

      // Calculate avgSpeed of current trip
      const totalSeconds = timeLocations.reduce((a, b) => a + b.time, 0)
      const avgSpeed = Math.round((timeLocations.reduce((a, b) => a + (b.speed * b.time), 0) / totalSeconds) * 10) / 10

      // Calculate fuel consumption
      let fuelConsumption = 0
      if (this.device.attributes.xpert) {
        try {
          const locationsFuelLevel = locations.filter(l => l.attributes.fuel && l.attributes.ignition).map(l => l.attributes.fuel)
          if (locationsFuelLevel.length > 5) {
            const startFuelLevel = Math.max(...locationsFuelLevel.slice(0, 5))
            const endFuelLevel = Math.min(...locationsFuelLevel.slice(-5))
            Vue.$log.debug('startFuelLevel:' + startFuelLevel + ' endFuelLevel:' + endFuelLevel)
            fuelConsumption = Math.round((startFuelLevel - endFuelLevel) * this.device.attributes.fuel_tank_capacity / 100)
          } else if (locationsFuelLevel.length > 0) {
            const startFuelLevel = locationsFuelLevel[0]
            const endFuelLevel = locationsFuelLevel[locationsFuelLevel.length - 1]
            Vue.$log.debug('startFuelLevel:' + startFuelLevel + ' endFuelLevel:' + endFuelLevel)
            fuelConsumption = Math.round((startFuelLevel - endFuelLevel) * this.device.attributes.fuel_tank_capacity / 100)
          }
        } catch (e) {
          Vue.$log.error(e)
        }
      }

      // Calculate stopTime of last trip
      if (this.trips.length > 0) {
        const lastTrip = this.trips[this.trips.length - 1]
        lastTrip.trip_stop_time = this.$moment(locations[0].fixTime).diff(this.$moment(lastTrip.positions[lastTrip.positions.length - 1].fixTime), 'seconds')
      }

      // Calculate drivingTime of current trip
      const totalDrivingTime = timeLocations.filter(t => t.type === 0).reduce((a, b) => a + b.time, 0)

      return {
        positions: locations,
        idlePositions: [],
        trip_start_fixtime: new Date(locations[0].fixTime).toLocaleString(),
        trip_end_fixtime: 0,
        trip_end_address: locations[locations.length - 1].address,
        trip_driving_time: totalDrivingTime,
        trip_idle_time: 0,
        trip_stop_time: undefined,
        trip_distance: distance,
        trip_avg_speed: avgSpeed,
        endPoi: this.findNearestPOI(locations[locations.length - 1]),
        xpert: this.device.attributes.xpert,
        fuel_consumption: fuelConsumption,
        avg_fuel_consumption: Math.round(distance > 0 ? fuelConsumption * 100 / (distance / 1000) : 0)
      }
    },
    async getSpeedTrips(positions) {
      const speedThreshold = this.device.attributes.overspeedThreshold || 0
      const self = this
      if (!this.device.attributes.overspeedByRoad) {
        Vue.$log.debug('Use vehicle speed limit')
        this.speedTrips = []
        let locations = []
        let startPos = false
        let geofence
        const trips = this.speedTrips
        const vehicleSpeedLimitThreshold = this.device.attributes.speedLimit + Number(speedThreshold) / 1.85200
        this.trips.forEach(function(tripPositions) {
          const currentSpeedTrips = []
          tripPositions.positions.forEach(function(position) {
            const overspeedEvents = position.events ? position.events.filter(e => e.type === 'deviceOverspeed') : []
            if (!startPos &&
              (position.speed > vehicleSpeedLimitThreshold || (overspeedEvents.length && overspeedEvents[0].geofenceId))) {
              locations.push(position)
              startPos = true
              geofence = overspeedEvents.length && overspeedEvents[0].geofenceId ? self.geofences.find(g => g.id === overspeedEvents[0].geofenceId) : undefined
            } else if (startPos && position.speed < vehicleSpeedLimitThreshold) {
              locations.push(position)
              const speedTrip = {
                positions: locations,
                speedLimit: geofence ? Math.round(geofence.attributes.speedLimit * 1.85200) : Math.round(self.device.attributes.speedLimit * 1.85200)
              }
              currentSpeedTrips.push(speedTrip)
              startPos = false
              locations = []
            } else if (startPos && position.speed > vehicleSpeedLimitThreshold) {
              locations.push(position)
            }
          })
          trips.push(currentSpeedTrips)
        })
      } else {
        routeMatch([this.device], positions).then(data => this.roadSpeedTrips(data))
      }
    },
    roadSpeedTrips(data) {
      if (data && data.length) {
        this.speedTrips = []
        let locations = []
        let startPos = false
        const trips = this.speedTrips

        this.trips.forEach(function(tripPositions) {
          const currentSpeedTrips = []
          data.forEach(d => { d.speed_limit = d.speed_limit || d.speedLimit })
          let speedLimit = data[0].speed_limit
          tripPositions.positions.forEach(position => {
            const speedPosition = data.find(d => d.positionId === position.id)
            if (startPos && (speedPosition && speedLimit !== speedPosition.speed_limit)) {
              locations.push(position)
              const speedTrip = {
                positions: locations,
                speedLimit: speedLimit
              }
              currentSpeedTrips.push(speedTrip)
              startPos = false
              locations = []
            }
            if (!startPos && speedPosition) {
              locations.push(position)
              speedLimit = speedPosition.speed_limit
              startPos = true
            }
            if (startPos && !speedPosition) {
              locations.push(position)
              const speedTrip = {
                positions: locations,
                speedLimit: speedLimit
              }
              currentSpeedTrips.push(speedTrip)
              startPos = false
              locations = []
            }
            if (startPos && speedPosition) {
              locations.push(position)
            }
          })
          trips.push(currentSpeedTrips)
        })
        this.drawSpeedMarkers()
        // vehicle should be on top of the route, so we remove and add the layer
        layerManager.removeRoutePlayLayer()
        layerManager.addRoutePlayLayer(vm.$static.currentFeature)
      }
    },
    findNearestPOI(position) {
      if (this.pois.length === 0) { return null }

      if (!position) { return null }

      const a = this.pois.map(p => {
        if (p.area) {
          const str = p.area.substring('CIRCLE ('.length, p.area.indexOf(','))
          const coord = str.trim().split(' ')
          return {
            id: p.id,
            distance: Math.round(lnglat.coordsDistance(parseFloat(coord[1]), parseFloat(coord[0]), position.longitude, position.latitude))
          }
        }
        return {
          id: p.id,
          distance: Number.MAX_SAFE_INTEGER
        }
      }).filter(a => a.distance < 100).sort((a, b) => (a.distance > b.distance) ? 1 : -1)

      if (a.length > 0) {
        return a[0].id
      }
    },
    drawSpeedTrip() {
      if (!this.speedTrips || !this.speedTrips[this.currentTrip]) {
        return
      }
      if (vm.$static.map.getSource(this.routeSpeedSource)) {
        Vue.$log.warn('ignoring layer ', this.routeSpeedSource, ', already exists...')
        return
      }
      if (!this.showRoutes) {
        Vue.$log.warn('ignoring layer ', this.routeSpeedSource, ', history mode off...')
        return
      }
      Vue.$log.debug('Draw speed trip')
      const speedCoordinates = this.speedTrips[this.currentTrip].map(t => t.positions.map(p => [p.longitude, p.latitude]))
      Vue.$log.debug(speedCoordinates)
      const speedLineString = []
      speedCoordinates.forEach(function(locations) {
        const lineStringTrip = { type: 'LineString', coordinates: locations }
        speedLineString.push(lineStringTrip)
      })
      const alertsGeoJSON = lnglat.getGeoJSONFeatures(speedLineString)
      Vue.$log.debug('Positions Route Alerts', alertsGeoJSON)
      vm.$static.map.addSource(this.routeSpeedSource, {
        type: 'geojson',
        data: alertsGeoJSON
      })
      vm.$static.map.addLayer(routeLayers.speedLayer(this.routeSpeedSource))
      vm.$static.map.getSource(this.routeSpeedSource).setData(alertsGeoJSON)
      this.drawSpeedMarkers()
      // vehicle should be on top of the route, so we remove and add the layer
      layerManager.removeRoutePlayLayer()
      layerManager.addRoutePlayLayer(vm.$static.currentFeature)
    },
    drawTrip() {
      this.$log.debug(this.currentTrip)
      if (this.currentTrip < 0) return
      this.drawStartEnd()
      if (vm.$store.state.settings.matchRoutes) {
        this.iterate()
      } else {
        const coordinates = this.trips[this.currentTrip].positions.map(p => [p.longitude, p.latitude])
        this.drawRoute(coordinates)
        if (coordinates.length > 1) {
          const mapBounds = bboxPolygon(bbox(helpers.lineString(this.map.getBounds().toArray())))
          const tripLine = helpers.lineString(coordinates)
          if (!booleanContains(mapBounds, tripLine) && !this.map.isMoving()) {
            this.map.fitBounds(bbox(tripLine), { padding: 100, linear: true })
          }
        }
      }
    },
    drawIdlePoints(positions) {
      const idlePositions = calculateIdlePositions(positions)
      const features = idlePositions.map(p => {
        return { type: 'Feature', properties: { ...p.attributes, ...p }, geometry: { type: 'Point', coordinates: [p.longitude, p.latitude] }}
      })
      const idlePointsGeoJSON = lnglat.getGeoJSONFeaturesColletion(features)
      this.createIdleLayer(idlePointsGeoJSON)
    },
    drawEventsPoints(positions) {
      this.$log.debug(this.currentTrip)
      if (this.currentTrip < 0) return

      const features = positions.filter(p => p.events).map(p => {
        return p.events.map(e => {
          if (!e.device) {
            notifications.addEventInfo(e)
          }

          return {
            type: 'Feature',
            properties: {
              id: e.id,
              type: e.type,
              description: e.description,
              device: e.device.name,
              content: e.content,
              icon: e.image,
              color: e.color ? e.color : '#3232b4',
              timestamp: p.fixTime,
              selected: false,
              speedLimit: e.attributes && e.attributes.speedLimit
            },
            geometry: { type: 'Point', coordinates: [p.longitude, p.latitude] }
          }
        })
      }
      ).flat()
      const eventsPointsGeoJSON = lnglat.getGeoJSONFeaturesColletion(features)
      this.createEventsLayer(eventsPointsGeoJSON)
    },
    distance(p, q) {
      const dx = p.x - q.x
      const dy = p.y - q.y
      return Math.sqrt(dx * dx + dy * dy)
    },
    drawStartEnd() {
      const maxScreenDistance = 77
      if (this.currentTrip < 0) return
      const positions = this.trips[this.currentTrip].positions
      if (!positions.length) {
        return
      }
      const start = [positions[0].longitude, positions[0].latitude]
      const end = [positions[positions.length - 1].longitude, positions[positions.length - 1].latitude]
      const sCoord = this.map.project(start)
      const eCoord = this.map.project(end)
      const d = this.distance(sCoord, eCoord)
      let el = document.createElement('div')
      el.className = 'marker '
      if (d < maxScreenDistance) { el.className += `rot${sCoord.x > eCoord.x ? 'r' : 'l'}` }
      let hour = this.$moment(positions[0].fixTime).format('HH:mm')
      Vue.$log.debug('adding start position on ', positions[0].deviceTime, hour)
      el.innerHTML = '<span><b>' + hour + '</b></span>'
      if (this.startMaker) { this.startMaker.remove() }
      if (this.endMarker) { this.endMarker.remove() }
      this.startMaker = new mapboxgl.Marker(el).setLngLat(start).addTo(vm.$static.map)
      this.startMaker.setPopup(this.getPopup(positions[0]))
      el = document.createElement('div')
      el.className = 'marker finish ' + (d > maxScreenDistance ? '' : `rot${sCoord.x > eCoord.x ? 'l' : 'r'}`)
      hour = this.$moment(positions[positions.length - 1].fixTime).format('HH:mm')
      el.innerHTML = '<span><b>' + hour + '</b></span>'
      this.endMarker = new mapboxgl.Marker(el).setLngLat(end)
      this.endMarker.addTo(vm.$static.map)
      this.endMarker.setPopup(this.getPopup(positions[positions.length - 1]))
    },
    drawSpeedMarkers() {
      const _speedTrips = this.speedTrips
      if (this.speedMarkers) {
        this.speedMarkers.map(m => m.remove())
      }
      const self = this
      if (_speedTrips && _speedTrips.length) {
        _speedTrips.forEach(speedTrips =>
          speedTrips.forEach((trip) => {
            const start = [trip.positions[0].longitude, trip.positions[0].latitude]
            const el = self.getMarkerElement('marker speed', trip.speedLimit || trip.speed_limit)
            self.speedMarkers.push(new mapboxgl.Marker(el).setLngLat(start))
            self.speedMarkers[self.speedMarkers.length - 1].addTo(vm.$static.map)
          }))
      }
    },
    getMarkerElement(classname, label) {
      const el = document.createElement('div')
      el.className = classname
      el.innerHTML = '<span><b>' + label + '</b></span>'
      return el
    },
    getFeature(p) {
      p.attributes.lowSignal = lowSignal(p)
      const feature = {
        type: 'Feature',
        properties: {
          text: this.device.name,
          description: '<div id=\'vue-vehicle-popup\'></div>'
        },
        geometry: {
          'type': 'Point',
          'coordinates': [p.longitude, p.latitude]
        },
        id: p.id
      }
      feature.properties = { ...feature.properties, ...p, ...p.attributes }
      return feature
    },
    drawAll(positions) {
      if (positions && positions.length > 0) {
        this.map.resize()
        const points = positions.map(p => this.getFeature(p))
        const coords = positions.map(p => [p.longitude, p.latitude])
        const bounds = lnglat.getBounds(coords)
        this.map.fitBounds(bounds, { maxZoom: vm.$static.map.getZoom(), padding: 70, linear: true })
        const pointsData = lnglat.getGeoJSONFeaturesColletion(points)
        const lineData = lnglat.getGeoJSON({ type: 'LineString', coordinates: coords })
        this.createAllTripsLayer(lineData, pointsData)
      }
    },
    iterate() {
      const positions = this.trips[this.currentTrip].positions
      if (this.i < positions.length) {
        const j = (this.i + 100) <= (positions.length) ? (this.i + 100) : (positions.length)
        Vue.$log.debug('slicing ', this.i, ' to ', j)
        this.drawRoute(
          positions.slice(this.i, j).map(p => [p.longitude, p.latitude]),
          positions.slice(this.i, j).map(p => Vue.moment(p.fixTime).unix()))
      }
    },
    drawRoute(positions, timestamps) {
      const lineString = { type: 'LineString', coordinates: positions }
      if (!settings.mapBoxRouteMatch) {
        const routeGeoJSON = this.getGeoJSON(lineString)
        Vue.$log.debug('Positions Route ', routeGeoJSON)
        this.createLayers(routeGeoJSON)
      } else {
        lnglat.matchRoute(positions, positions.map(() => [25]), timestamps, this.onRouteMatch)
      }
    },
    getGeoJSON(coords) {
      return lnglat.getGeoJSON(coords)
    },
    createLayers(routeGeoJSON) {
      if (vm.$static.map.getSource(this.routeSource)) {
        Vue.$log.warn('ignoring layer ', this.routeSource, ', already exists...')
        return
      }
      vm.$static.map.addSource(this.routeSource, {
        type: 'geojson',
        data: routeGeoJSON
      })
      Vue.$log.debug('adding layer', this.routeSource)
      vm.$static.map.addLayer(routeLayers.routeLayer(this.routeSource))
      vm.$static.map.getSource(this.routeSource).setData(routeGeoJSON)
    },
    createEventsLayer(eventsGeoJSON) {
      if (vm.$static.map.getSource(this.routeEventsSource)) {
        Vue.$log.warn('ignoring layer ', this.routeEventsSource, ', already exists...')
        return
      }
      vm.$static.map.addSource(this.routeEventsSource, {
        type: 'geojson',
        data: eventsGeoJSON
      })
      Vue.$log.debug('adding events layer', eventsGeoJSON)
      vm.$static.map.addLayer(routeLayers.eventsLayer(this.routeEventsSource))
      vm.$static.map.on('click', this.routeEventsSource, this.onEventMouseEnter)
      layerManager.hideLayer(this.routeEventsSource, !this.isEventsChartVisible)
    },
    createIdleLayer(idleGeoJSON) {
      if (vm.$static.map.getSource(this.routeIdleSource)) {
        Vue.$log.warn('ignoring layer ', this.routeIdleSource, ', already exists...')
        return
      }
      vm.$static.map.addSource(this.routeIdleSource, {
        type: 'geojson',
        data: idleGeoJSON
      })
      Vue.$log.debug('adding idle layer', idleGeoJSON)
      vm.$static.map.addLayer(routeLayers.idleLayer(this.routeIdleSource))
      vm.$static.map.on('click', this.routeIdleSource, this.onIdleMouseEnter)
    },
    onIdleMouseEnter(e) {
      vm.$static.map.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const description = `<span style="padding: 3px">${this.$t('Ralenti')}: ${e.features[0].properties.idle_time}</span>`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }
      this.popup
        .setLngLat(coordinates)
        .setHTML(description)
      if (!this.popupOpened) {
        this.popup.addTo(vm.$static.map)
        lnglat.showPopup(e.features[0], this.device)
      }
    },
    onEventMouseEnter(e) {
      vm.$static.map.getCanvas().style.cursor = 'pointer'
      lnglat.showEventPopup(e.features[0], new mapboxgl.Popup({
        offset: [0, -20]
      }), function() {})
    },
    createAllTripsLayer(routeGeoJSON, points) {
      this.$store.commit('map/createAllTripsLayer', {
        routeGeoJSON,
        points,
        routeColor: this.device.attributes.routeColor }
      )
    },
    mouseEnterArrow(e) {
      const feature = e.features[0]
      this.lastArrowEntered = feature.id
      if (feature.id) {
        vm.$static.map.setFeatureState({ source: this.allTripsArrowsSource, id: feature.id },
          { hover: true })
      }
      const position = { ...feature.properties }
      position.attributes = { ...feature.properties }
      lnglat.showPopup(feature, this.device)
    },
    mouseLeaveArrow() {
      lnglat.hidePopup(this.device)
      if (this.lastArrowEntered) {
        vm.$static.map.setFeatureState({ source: this.allTripsArrowsSource, id: this.lastArrowEntered },
          { hover: false })
      }
    },
    drawIteration(routeGeoJSON) {
      this.createLayers(routeGeoJSON)
      this.i += 99
      this.iterate()
    },
    onRouteMatch(r) {
      if (!r.data.matchings[0]) {
        Vue.$log.warn('route matching returned 0!!!')
        return
      }
      const routeGeoJSON = this.getGeoJSON(r.data.matchings[0].geometry)
      this.drawIteration(routeGeoJSON)
    },
    routePlay() {
      this.removeLayers(true)
      animation.updateFeature()
      this.$log.info('CurrentPositionData emit routeMatchFinished')
      serverBus.$emit(event.routeMatchFinished)
    },
    resizeDiv() {
      Vue.$log.debug('currentpositiondata')
      if (document.getElementById('map')) {
        this.width = 'width:' + document.getElementById('map').clientWidth + 'px'
      } else {
        this.$log.warn('currentposition, no map element...')
      }
    },
    updateDate() {
      const positions = sharedData.getPositions()
      if (positions && positions.length > 0 && positions[this.currentPos]) {
        const pos = positions[this.currentPos]
        this.formattedDate = this.$moment(pos.fixTime).format('YYYY-MM-DD HH:mm:ss')
        if (pos.speed && pos.speed > 0) {
          this.formattedDate += (' ' + ~~(pos.speed * 1.852) + 'km/h')
        }
      } else {
        this.formattedDate = ''
      }
    },
    onTripChanged(newTrip) {
      this.onPosChanged(sharedData.getPositions().indexOf(this.trips[newTrip].positions[0]))
    },
    onPosChanged(newPos) {
      const positions = sharedData.getPositions()
      this.currentPos = newPos
      if (!this.device) {
        Vue.$log.debug('CurrentPositionData, ignoring, no device...')
        return
      }
      if (this.device.id !== vm.$data.currentDevice.id) {
        Vue.$log.debug('CurrentPositionData ignoring, my device:', this.device.name, ' selected: ', vm.$data.currentDevice.name)
        return
      }
      if (newPos >= positions.length) {
        Vue.$log.warn('CurrentPositionData ignoring, newPos out of array: ', newPos, positions.length)
        return
      }
      const origin = this.oldPos
      this.updateDate()
      if (this.isPlaying) {
        if (newPos < this.oldPos) {
          this.$log.info('ignoring animation, end of route ', newPos, this.oldPos)
          this.oldPos = newPos
          serverBus.$emit(event.routeMatchFinished)
          return
        }
        if (JSON.stringify(sharedData.getPositions()[origin]) === JSON.stringify(sharedData.getPositions()[newPos])) {
          this.$log.info('CurrentPositionData emit routeMatchFinished origin equals destination', origin, newPos)
          serverBus.$emit(event.routeMatchFinished)
        } else {
          this.$log.info('animating from ', origin, ' to ', newPos + 1)
          animation.animate(vm.$static.currentFeature,
            sharedData.getPositions().slice(origin, newPos + 1).map(x => [x.longitude, x.latitude]),
            sharedData.getPositions()[newPos + 1].course)
        }
        if (newPos === sharedData.getPositions().length - 1) {
          this.$store.dispatch('transient/togglePlaying')
        }
      } else {
        if (!this.trips[this.currentTrip]) {
          Vue.$log.debug('no current trip...')
          return
        }
        const newDate = new Date(positions[newPos].fixTime)
        const oldTrip = this.currentTrip
        while (this.currentTrip < this.trips.length - 1 && this.trips[this.currentTrip].positions.slice(-1)[0] && newDate > new Date(this.trips[this.currentTrip].positions.slice(-1)[0].fixTime)) {
          this.currentTrip++
        }
        while (this.currentTrip > 0 && this.trips[this.currentTrip].positions[0] && newDate < new Date(this.trips[this.currentTrip].positions[0].fixTime)) {
          this.currentTrip--
        }
        if (oldTrip !== this.currentTrip) {
          this.$log.debug('newTrip', this.currentTrip)
          const t = this.currentTrip
          this.currentTrip = oldTrip
          this.removeLayers(true)
          this.currentTrip = t
          this.drawTripData(positions)
        }
        if (!vm.$static.map.isMoving() && !lnglat.contains(vm.$static.map.getBounds(), positions[newPos])) {
          vm.$static.map.panTo({ lng: positions[newPos].longitude, lat: positions[newPos].latitude })
        }
        const position = positions[newPos]
        vm.$static.currentFeature.properties.speed = positions[newPos].speed
        vm.$static.currentFeature.properties.course = positions[newPos].course
        vm.$static.currentFeature.geometry.coordinates = [positions[newPos].longitude, positions[newPos].latitude]
        vm.$static.currentFeature.properties.address = positions[newPos].address
        animation.updateFeature()
        if (this.zoomToDevice &&
          // check if trip was selected
          this.trips[this.currentTrip].positions[0].fixTime !== position.fixTime &&
          // check if dates changed
          positions.slice(-1)[0] !== position) {
          if (position.speed < 3) {
            this.map.flyTo({ center: [position.longitude, position.latitude], zoom: stopZoom })
          } else {
            this.map.panTo([position.longitude, position.latitude])
          }
        }
      }
      if (newPos < positions.length - 1) {
        this.oldPos = newPos
      }
    },
    datesChanged() {
      if (this.device.id === vm.$data.currentDevice.id && this.showRoutes) {
        if (this.dateRange) {
          this.loadingRoutes = true
          this.getRoute(this.dateRange[0], this.dateRange[1]).catch(e => {
            console.error(e)
            this.$alert(e.message)
            this.loadingRoutes = false
          })
        }
      }
    },
    getPopup(position) {
      const feature = this.getFeature(position)
      const popup = new mapboxgl.Popup({ class: 'card2', offset: 25 })
      const el = 'vep' + new Date().getTime()
      popup.setHTML(`<div id="${el}"></div>`)
      popup.on('open', () => {
        this.$store.commit('SET_POPUP_OPENED', true)
        popUps.forEach(p => p.remove())
        this.popup.remove()
        const VD = Vue.extend(VehicleDetail)
        new VD({
          el: `#${el}`,
          i18n: i18n,
          data: {
            device: vm.$data.currentDevice,
            position: position,
            feature: feature,
            routePoint: true
          },
          store: store
        })
      })
      popup.on('close', () => {
        this.$store.commit('SET_POPUP_OPENED', false)
      })
      return popup
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    font-weight: bold;
    font-size: 18px;
    color: #5a5e66;
  }

  input[type="date"]::-webkit-clear-button {
    display: none;
    margin: 0;
  }

  /* Removes the spin button */
  input[type="date"]::-webkit-inner-spin-button {
    display: none;
    margin:0;
  }

  /* A few custom styles for date inputs */
  input[type="date"] {
    color: #5a5e66;
    font-size: 19px;
    border-width:1px;
    padding:0;
    visibility: visible !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .textFormat {
    color: gray;
    font-size: 14px;
  }
</style>
<style>
/*noinspection CssUnusedSymbol*/
.popup-content .mapboxgl-popup-content {
    border-radius: 5px;
    padding: 0;
    text-align: center;
    min-width: 70px;
    color: black;
    background-color: #F9B218;
  }

.popup-content .mapboxgl-popup-tip {
  border-bottom-color: #F9B218 !important;
  border-top-color: #F9B218 !important;
}

</style>
