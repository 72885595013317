<template>
  <div>
    <el-select
      v-model="selectedAlertType"
      clearable
      style="width: 100%; margin-bottom: 3px"
      :multiple="true"
      :placeholder="$t('alerts.title')"
      value=""
    >
      <el-option v-for="item in userAlertTypes" :key="item" :label="alertTypeTranslation(item)" :value="item" />
    </el-select>
    <div v-if="!historyMode">
      <el-select
        v-model="alertsSearchPeriod"
        style="margin-bottom: 5px; width: 100%"
        @change="getAlerts"
      >
        <el-option
          v-for="item in alertsSearchPeriods"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div>
      <el-table
        id="alertTable"
        v-loading="loadingEvents"
        v-el-table-infinite-scroll="load"
        style="padding: 5px"
        :cell-style="cellStyle"
        :data="filteredEvents"
        :show-header="false"
        :height="height"
        highlight-current-row
        stripe
        @row-click="alertSelected"
      >
        <el-table-column
          prop="name"
          label=""
          width="5"
          heigth="10"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          sortable=""
          heigth="1"
        >
          <template slot-scope="scope">
            <div style="padding: 10px 0">
              <div style="line-height: normal">
                <i :style="'width: 20px; font-size: 12px; color: '+(scope.row.color ? scope.row.color : '#3232b4')" :class="scope.row.image"></i>
                <span style="font-weight: bold">{{ getDescription(scope.row) }}</span>
                <img v-if="scope.row.attributes.speedLimit" style="width:20px; vertical-align:middle; padding-left: 10px" :src="`/github/regulatory--maximum-speed-limit-${Math.round(scope.row.attributes.speedLimit*1.852)}--g1.svg`" alt="">
              </div>
              <div style="line-height: normal">
                <span style="font-size: 12px"><i class="fas fa-car listIcon" style="width: 20px"></i>
                  {{ scope.row.device && scope.row.device.name }}</span>
              </div>
              <div style="line-height: normal">
                <span style="font-size: 12px">
                  <i v-if="scope.row.type === 'geofenceExit' || scope.row.type === 'geofenceEnter'" class="fas fa-map-marked listIcon" style="width: 20px"></i>
                  <i v-if="scope.row.type === 'deviceOverspeed'" class="fas fa-tachometer-alt listIcon" style="width: 20px"></i>
                  <i v-if="scope.row.type === 'deviceFuelDrop'" class="fas fa-gas-pump listIcon" style="width: 20px"></i>
                  {{ scope.row.content }}
                </span>
              </div>
              <div style="line-height: normal; font-size: 12px">
                <i class="fas fa-clock listIcon" style="width: 20px"></i>{{ scope.row.serverTime | moment('from', currentTime) }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { serverBus, vm } from '@/main'
import styles from '../../styles/element-variables.scss'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import * as alertType from '../../alerts/alertType'
import * as event from '@/events'

export default {
  name: 'AlertTable',
  props: {
    filterKey: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      selectedAlertType: [],
      alertsSearchPeriod: '',
      selected: false,
      count: 20
    }
  },
  computed: {
    ...mapGetters(['historyMode', 'currentTime', 'events', 'alerts', 'devices', 'loadingEvents']),
    height() {
      return 'calc(100vh - ' + styles.alertListHeaderHeight + ')'
    },
    map: function() {
      return vm.$static.map
    },
    filteredEvents() {
      const filterKey = this.filterKey && this.filterKey.toLowerCase()
      let events = this.events
      if (filterKey) {
        events = events.filter(function(row) {
          return Object.keys(row).some(function(key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          }) || row.device.name.toLowerCase().includes(filterKey.toLowerCase())
        })
      }
      if (this.selectedAlertType.length > 0) {
        events = events.filter(e => this.selectedAlertType.includes(e.type) ||
          (this.selectedAlertType.includes(e.originalType) &&
            this.alerts.find(a => a.notification.attributes.alarms.split(',').includes(e.type))))
      } else {
        events = events.filter(e =>
          this.alerts.find(
            a => a.notification.type === e.type ||
            (a.notification.attributes.alarms && a.notification.attributes.alarms.split(',').includes(e.type))
          )
        )
      }
      events = events.sort((a, b) => {
        return (a.timestamp === b.timestamp ? 0 : a.timestamp > b.timestamp ? -1 : 1)
      })
      return events.slice(0, this.count)
    },
    getVehicleName(row) {
      if (row.vehicle) {
        return row.vehicle.name
      }

      return ''
    },
    userAlertTypes() {
      const userAlerts = this.alerts.map(a => this.getAlertType(a))
      return [...new Set(userAlerts.map(t => alertType.alertTypes.includes(t) ? t : t.split(',')).flat())]
    },
    alertsSearchPeriods: function() {
      return [{
        value: 'last_one_hour',
        label: this.$t('Última hora')
      }, {
        value: 'last_six_hours',
        label: this.$t('Últimas 6 horas')
      }, {
        value: 'last_twelve_hours',
        label: this.$t('Últimas 12 horas')
      }, {
        value: 'last_twentyfour_hours',
        label: this.$t('Últimas 24 horas')
      }]
    }
  },
  methods: {
    load() {
      this.count += 20
    },
    cellStyle(row) {
      let result = 'padding: 0; '
      if (row.columnIndex === 0 && row.row.isNew) {
        result += 'background-color: Red'
      }
      return result
    },
    getAlertType(item) {
      if (item.notification.type === 'alarm') {
        return item.notification.attributes.alarms
      } else {
        return item.notification.type
      }
    },
    loadAlerts() {
      if (this.alerts.length === 0) {
        this.getAlerts()
      }
    },
    async getAlerts() {
      this.$store.commit('transient/SET_LOADING_EVENTS', true)
      const hours = this.getSearchHours()
      const _alertTypes = this.selectedAlertType.length > 0 ? this.selectedAlertType : this.userAlertTypes

      await this.$store.dispatch('transient/fetchEvents', {
        start: Vue.moment().subtract(hours, 'hour').toDate(),
        end: new Date(),
        types: [...new Set(_alertTypes.map(t => alertType.traccarAlertTypes.includes(t) ? t : 'alarm'))]
      })
      this.$store.commit('transient/SET_LOADING_EVENTS', false)
    },
    getSearchHours() {
      switch (this.alertsSearchPeriod) {
        case 'last_one_hour':
          return 1
        case 'last_six_hours':
          return 6
        case 'last_twelve_hours':
          return 12
        default:
          return 24
      }
    },
    alertSelected: function(alert) {
      if (alert) {
        if (alert.isNew) {
          alert.isNew = false
          this.$store.dispatch('decUnreadItems')
        }
        serverBus.$emit(event.eventSelected, alert)
      }
    },
    alertTypeTranslation(item) {
      return alertType.alertTypes.includes(item) ? this.$t('settings.alert_' + item) : item
    },
    getDescription(e) {
      return (e.type === 'sensor' && e.device.attributes.sensor1) ||
        (vm.$t('settings.alert_' + e.type) !== 'settings.alert_' + e.type ? vm.$t('settings.alert_' + e.type) : e.type)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobileScroll {
  -webkit-overflow-scrolling: touch;
}

</style>
<style lang="scss">
@import '../../styles/element-variables.scss';

.listIcon {
  color: $--color-primary
}

.el-table .new-row {
  background: $--color-primary
}

.el-table .normal-row {
  background: $--color-success
}
</style>
