<template>
  <!--suppress HtmlUnknownAttribute -->
  <div v-show="historyMode" v-loading="loadingRoutes" class="historyPanel2">
    <div style="position: relative; height:160px; padding-right: 20px">
      <speed-chart :update="updateChart" />
    </div>
    <div>
      <div :style="`padding-left:${sliderLeftPadding}px; padding-right: 65px; height:26px`">
        <label>
          <input
            v-model="embeddedSliderPos"
            type="range"
            :max="maxPos"
            :min="minPos"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { serverBus, vm, sharedData } from '@/main'
import Vue from 'vue'
import * as lnglat from '../../utils/lnglat'
import * as consts from '../../utils/consts'
import SpeedChart from './SpeedChart'

import { mapGetters } from 'vuex'
import { isIdlePosition } from '@/utils/positions'
import { findNearestPOI } from '@/utils/lnglat'
import { traccarAlertTypes } from '@/alerts/alertType'

export default {
  name: 'HistoryPanel',
  components: { SpeedChart },
  data() {
    return {
      currentPos: 0,
      updateChart: false,
      embeddedSliderPos: 0,
      currentPos_: 0
    }
  },
  computed: {
    ...mapGetters(['minPos', 'maxPos', 'isPlaying', 'historyMode', 'trips', 'stops']),
    isMobile() {
      return lnglat.isMobile()
    },
    device() {
      return vm.$data.currentDevice
    },
    loadingRoutes: {
      get() { return vm.$data.loadingRoutes },
      set(value) { vm.$data.loadingRoutes = value }
    },
    minDate: {
      get() {
        return this.$moment(vm.$data.routeMinDate).format('YYYY-MM-DD')
      },
      set(newVal) {
        vm.$data.routeMinDate = this.$moment(newVal, 'YYYY-MM-DD').toDate()
      }
    },
    maxDate: {
      get() {
        return this.$moment(vm.$data.routeMaxDate).format('YYYY-MM-DD')
      },
      set(newVal) {
        vm.$data.routeMaxDate = this.$moment(newVal, 'YYYY-MM-DD').toDate()
      }
    },
    sliderLeftPadding() {
      return 40
    }
  },
  watch: {
    sliderPosStyle() {
      return 'padding-left:48px; padding-right: 65px'
    },
    embeddedSliderPos(newValue) {
      this.sliderPos(newValue)
    },
    isPlaying(newValue) {
      this.$log.debug('isPlaying changed to ', newValue, this.currentPos, sharedData.getPositions().length - 1)
      if (newValue) {
        if (sharedData.getPositions() && this.currentPos === sharedData.getPositions().length - 1) {
          this.$log.debug('setting currentPos to 0')
          this.currentPos_ = 0
          serverBus.$emit('autoSliderChange', this.minPos)
        }
      }
      serverBus.$emit('routePlay')
    },
    currentPos(newPos) {
      serverBus.$emit('posChanged', newPos)
    }
  },
  created() {
    serverBus.$on('routeFetched', this.updateMinMax)
    serverBus.$on('routeMatchFinished', this.playNext)
    serverBus.$on('sliderChanged', this.sliderPos)
    serverBus.$on('autoSliderChange', this.autoSliderChange)
    serverBus.$on('clickPlay', this.click)
    serverBus.$on('clickBack', this.clickBackward)
    serverBus.$on('clickForward', this.clickForward)
    window.addEventListener('resize', this.resizeDiv)
  },
  beforeDestroy() {
    serverBus.$off('routeFetched', this.updateMinMax)
    serverBus.$off('routeMatchFinished', this.playNext)
    serverBus.$off('sliderChanged', this.sliderPos)
    serverBus.$off('autoSliderChange', this.autoSliderChange)
    serverBus.$off('clickPlay', this.click)
    serverBus.$off('clickBack', this.clickBackward)
    serverBus.$off('clickForward', this.clickForward)
    window.removeEventListener('resize', this.resizeDiv)
  },
  mounted() {
    this.resizeDiv()
  },
  methods: {
    autoSliderChange(newValue) {
      this.$log.debug(newValue)
      this.sliderPos(newValue)
      this.embeddedSliderPos = newValue
    },
    sliderPos(newValue) {
      const indexArray = sharedData.getPositionIndex()
      if (!indexArray) { return }
      const pos = indexArray[newValue]
      if (pos && pos.index > 0 && !this.isPlaying) {
        this.currentPos = pos.index
      } else {
        let i = newValue
        while (!indexArray[i] && i < this.maxPos) { i++ }
        const nextRight = i
        i = newValue
        while (!indexArray[i] && i > this.minPos) { i-- }
        const nextLeft = i
        i = (nextRight - newValue > newValue - nextLeft) ? nextLeft : nextRight
        if (indexArray[i]) {
          this.currentPos = indexArray[i].index
        } else { this.$log.warn('no latlon at index ', i) }
      }
    },
    resizeDiv: function() {
      if (document.getElementById('map')) {
        this.width = 'z-index=10000; width:' + (document.getElementById('map').clientWidth) + 'px'
      } else {
        Vue.$log.warn('resizing div but no map on dom...')
      }
    },
    fillGraphData() {
      const series = this.getSpeedValues()
      const seriesFuelSensor = sharedData.getPositions().filter(p => p.fuelLevel || p.attributes.fuel).map(x => {
        return { y: x.fuelLevel || x.attributes.fuel, x: this.$moment(x.fixTime).toDate() }
      })
      const seriesRPM = this.getRPMValues()
      const seriesEvents = this.getEventsValues()
      const seriesIgnitionOff = this.getIgnitionValues()
      const temperatures = this.getTemperatureValues()
      const seriesIdle = this.getIdleValues()
      sharedData.setChartData(series, seriesFuelSensor, seriesRPM, seriesEvents, seriesIgnitionOff, temperatures, seriesIdle)
      this.updateChart = !this.updateChart
    },
    getRPMValues() {
      if (this.device.attributes.xpert || sharedData.getPositions().find(p => p.attributes.rpm)) {
        let currentRPM = null
        const rpmData = []
        let transition = false
        sharedData.getPositions().forEach(p => {
          if (!p.attributes.ignition) {
            currentRPM = 0
            transition = true
          } else if (((p.attributes.xpert && p.attributes.xpert.filter &&
            p.attributes.xpert.filter(x => x.type === '1').length > 0) || p.attributes.rpm)) {
            currentRPM = p.attributes.rpm ? p.attributes.rpm : p.attributes.xpert.filter(x => x.type === '1')[0].rpm
            if (transition) {
              rpmData.push({ y: 0, x: this.$moment(p.fixTime).toDate(), ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0) })
              transition = false
            }
          } else {
            currentRPM = null
          }

          if (currentRPM !== null) {
            return rpmData.push({ y: currentRPM, x: this.$moment(p.fixTime).toDate(), ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0) })
          }
        })
        return rpmData
      }
    },
    getTemperatureValues() {
      return sharedData.getPositions().map(p => {
        if (p.attributes && p.attributes.temp1 === 0) {
          return { y: 0, x: this.$moment(p.fixTime).toDate(), ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0) }
        }

        if (p.attributes && p.attributes.temp2 === 0) {
          return { y: 0, x: this.$moment(p.fixTime).toDate(), ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0) }
        }

        const tempValue = (p.attributes && p.attributes.temp1 !== 175 &&
        p.attributes.temp1 !== 3000 && p.attributes.temp1) ||
        (p.attributes && p.attributes.temp2 !== 175 && p.attributes.temp2 !== 3000 && p.attributes.temp2)
        return { y: tempValue || null,
          x: this.$moment(p.fixTime).toDate(), ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0) }
      })
    },
    getIdleValues() {
      let segmentStart = true
      let positionStart = null
      let nearestPOI = null
      const data = sharedData.getPositions().map(p => {
        if (isIdlePosition(p)) {
          if (positionStart === null) {
            positionStart = p.fixTime
            nearestPOI = findNearestPOI(p, true)
          }
          const value1 = { y: 0, x: this.$moment(p.fixTime).toDate(),
            ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0),
            idleStart: positionStart,
            nearestPOI: nearestPOI
          }
          const value2 = { y: 10, x: this.$moment(p.fixTime).toDate(),
            ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0),
            idleStart: positionStart,
            nearestPOI: nearestPOI
          }
          segmentStart = true
          return [value1, value2]
        } else {
          if (segmentStart) {
            const value1 = { y: 0, x: this.$moment(p.fixTime).toDate(),
              ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0),
              idleStart: positionStart,
              idleEnd: p.fixTime
            }
            const value2 = { undefined, x: this.$moment(p.fixTime).toDate(),
              ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0)
            }
            segmentStart = false
            positionStart = null
            nearestPOI = null
            return [value1, value2]
          }
        }
      }).flat()

      let positionEnd = null
      for (let i = data.length - 1; i >= 0; i--) {
        const item = data[i]
        if (item && item.idleEnd) {
          positionEnd = item.idleEnd
        } else if (item && positionEnd) {
          item.idleEnd = positionEnd
        }
      }

      return data
    },
    getIgnitionValues() {
      let segmentStart = true
      let positionStart = null
      let nearestPOI = null
      const data = sharedData.getPositions().map(p => {
        if (!p.attributes.ignition) {
          if (positionStart === null) {
            positionStart = p.fixTime
            nearestPOI = findNearestPOI(p, true)
          }
          const value1 = { y: 0, x: this.$moment(p.fixTime).toDate(),
            ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0),
            stopStart: positionStart,
            nearestPOI: nearestPOI
          }
          const value2 = { y: 10, x: this.$moment(p.fixTime).toDate(),
            ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0),
            stopStart: positionStart,
            nearestPOI: nearestPOI
          }
          segmentStart = true
          return [value1, value2]
        } else {
          if (segmentStart) {
            const value1 = { y: 0, x: this.$moment(p.fixTime).toDate(),
              ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0),
              stopStart: positionStart,
              stopEnd: p.fixTime
            }
            const value2 = { undefined, x: this.$moment(p.fixTime).toDate(), ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0) }
            segmentStart = false
            positionStart = null
            nearestPOI = null
            return [value1, value2]
          }
        }
      }).flat()

      let positionEnd = null
      for (let i = data.length - 1; i >= 0; i--) {
        const item = data[i]
        if (item && item.stopEnd) {
          positionEnd = item.stopEnd
        } else if (item && positionEnd) {
          item.stopEnd = positionEnd
        }
      }

      return data
    },
    getEventsValues() {
      return sharedData.getPositions().filter(p => p.events).map(x => {
        return {
          r: x.events.length + 2,
          y: 0,
          x: this.$moment(x.fixTime).toDate(),
          label: this.alarmTypeLabel(x.events[0]),
          ignition: (x.attributes.ignition ? (isIdlePosition(x) ? 2 : 1) : 0)
        }
      })
    },
    alarmTypeLabel(event) {
      return event.type === 'alarm'
        ? this.$t('settings.alert_' + event.attributes.alarm)
        : (traccarAlertTypes.includes(event.type)
          ? this.$t('settings.alert_' + event.type)
          : event.type)
    },
    getSpeedValues() {
      return sharedData.getPositions().map(p => {
        const trip = this.trips.find(t => {
          const start = t.positions[0] || null
          const end = t.positions[t.positions.length - 1] || null
          if (start && end) {
            return new Date(start.fixTime).getTime() <= new Date(p.fixTime).getTime() && new Date(end.fixTime).getTime() > new Date(p.fixTime).getTime()
          }
          return false
        })
        return { y: p.speed * 1.852, x: new Date(p.fixTime),
          trip: trip,
          ignition: (p.attributes.ignition ? (isIdlePosition(p) ? 2 : 1) : 0),
          address: p.address
        }
      }).flat()
    },
    click: function() {
      this.$store.dispatch('transient/togglePlaying')
    },
    clickForward: function() {
      if (this.currentPos < this.positions.length - 1) {
        serverBus.$emit('autoSliderChange', Vue.moment(this.positions[++this.currentPos].fixTime).unix())
      } else { Vue.$log.debug('ignoring forward sliderPos: ', this.currentPos) }
    },
    clickBackward: function() {
      if (this.currentPos > 0) {
        serverBus.$emit('autoSliderChange', Vue.moment(this.positions[--this.currentPos].fixTime).unix())
      }
    },
    initIndexArray() {
      const indexArray = {}
      const positions = sharedData.getPositions()
      const self = this
      positions.forEach(function(item, index) {
        item.index = index
        indexArray[self.$moment(item.fixTime).unix()] = item
      })
      sharedData.setPositionIndex(indexArray)
    },
    updateMinMax() {
      const self = this
      this.positions = sharedData.getPositions()
      const positions = this.positions
      if (positions && positions[0] && positions.length > 0) {
        this.$store.dispatch(
          'map/setMinPos',
          this.$moment(positions[0].fixTime).unix()
        ).then(() => {
          this.$store.dispatch('map/setMaxPos',
            this.$moment(positions[positions.length - 1].fixTime).unix()
          ).then(() => {
            if (positions.length > 0) {
              self.initIndexArray()
              self.fillGraphData()
              setTimeout(() => serverBus.$emit('autoSliderChange',
                this.$route.query.date ? this.$moment.utc(this.$route.query.date).unix() : self.maxPos), 200)
            } else {
              Vue.$log.debug('no positions: ', sharedData.getPositions())
            }
            this.loadingRoutes = false
          })
        })
      }
    },
    playNext() {
      this.$log.debug('HistoryPanel ', this.isPlaying, this.currentPos, this.currentPos_)
      if (this.isPlaying) {
        if (this.currentPos < this.positions.length - 1) {
          this.currentPos_ = Math.max(this.currentPos_, this.currentPos)
          if ((this.currentPos_ + consts.routeSlotLength) < this.positions.length) {
            this.currentPos += consts.routeSlotLength
          } else if (this.currentPos < (this.positions.length - 1)) {
            this.currentPos = this.positions.length - 1
          }
          this.$log.info('HistoryPanel emit autoSliderChange ', this.currentPos, this.positions[this.currentPos].fixTime)
          serverBus.$emit('autoSliderChange', Vue.moment(this.positions[this.currentPos].fixTime).unix())
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/element-variables.scss';
  .historyPanel2 {
    font-size: 15px;
    margin: 0 !important;
    border-right:0;
  }
  .playButtons {
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 10px;
    color:$--color-primary;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size:20px;
  }

  @media only screen and (min-width: 768px) {
    input[type=range] {
      -webkit-appearance: none;
      width: 100%;
    }
    input[type=range]:focus {
      outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
      height: 2px;
      background: $--color-info;
    }
    input[type=range]::-webkit-slider-thumb {
      box-shadow: 2px 2px 4px $--color-info;
      background: $--color-white;
      border: 2px solid;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -10px;
    }
  }
</style>
